import QueryString from 'qs';
import type { ComponentProps, FC, MouseEvent } from 'react';
import { useState } from 'react';

import { useCVContext } from '@/hooks/useCVContext';
import { apiRoutes } from '@/services/config';
import type { DownloadCVFormat } from '@/services/cvGenerator';
import { Themes } from '@/services/cvGenerator';

import Button from '../Button';
import FeedbackDialog from './FeedbackDialog';

type Props = {
  id: number;
  buttonProps?: ComponentProps<typeof Button>;
  theme?: Themes;
  disabled?: boolean;
  visible?: string[];
  confirm?: string;
};

export const downloadUrl = (
  id: number | number[],
  type: DownloadCVFormat,
  theme?: Themes,
  visible?: string[]
) => {
  if (!Array.isArray(id) || id.length === 1)
    return `${
      apiRoutes.cvGenerator.context.detail(Array.isArray(id) ? id[0] : id)
        .download
    }?${QueryString.stringify(
      {
        type,
        template: theme,
        visibility: visible,
      },
      { arrayFormat: 'repeat' }
    )}`;
  return `${apiRoutes.cvGenerator.context.downloadZip}?${QueryString.stringify(
    { cv: id, type, theme, visibility: visible },
    { arrayFormat: 'repeat' }
  )}`;
};
const CVDownloadButton: FC<Props> = ({
  id,
  buttonProps,
  theme = Themes.LIGHT,
  visible,
  confirm,
}) => {
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const { refetch } = useCVContext(
    id,
    {
      enabled: false,
    },
    true
  );

  const onDownload = async (e: MouseEvent, type: DownloadCVFormat) => {
    e.preventDefault();
    window.open(downloadUrl(id, type, theme, visible), '_self');
    const { data } = await refetch();
    if (data?.askForFeedback) setFeedbackDialogOpen(true);
  };
  return (
    <>
      <Button
        variant="unstyled"
        extraActionsTriggerButtonClassName="!border-l-0 bg-primary-600 !pl-0 !pr-2"
        extraActionsButtonClassName="!font-normal"
        className="!bg-primary-600 !pr-2"
        prefixIcon="material-symbols:download"
        confirm={confirm}
        size="long"
        onClick={(e) => {
          onDownload(e, 'PDF');
        }}
        extraActions={[
          {
            label: 'Save as DOCX',
            onClick: (e) => {
              onDownload(e, 'DOCX');
            },
            prefixIcon: 'material-symbols:download',
            confirm,
          },
          {
            label: 'Download JSON',
            onClick: (e) => {
              onDownload(e, 'JSON');
            },
            prefixIcon: 'material-symbols:download',
            confirm,
          },
        ]}
        popoverContentProps={{
          className:
            'w-fit rounded-2xl !bg-gradient-to-r from-black from-70% text-white border-primary-500 to-secondary-900',
        }}
        {...buttonProps}
      >
        Save as PDF
      </Button>
      <FeedbackDialog
        id={id}
        open={feedbackDialogOpen}
        onOpenChange={setFeedbackDialogOpen}
      />
    </>
  );
};

export default CVDownloadButton;
