import { DividerWithText as BaseDividerWithText } from '@mindpal-co/mindpal-ui';
import classNames from 'classnames';
import type { ComponentProps, FC } from 'react';

import Text from './Text';

type Props = ComponentProps<typeof BaseDividerWithText> & {
  textClassName?: React.ComponentProps<'span'>['className'];
};

const DividerWithText: FC<Props> = ({
  textClassName,

  children,
  className,
}) => {
  return (
    <BaseDividerWithText className={className}>
      <Text
        as="span"
        className={classNames('inline-block text-neutral-400', textClassName)}
      >
        {children}
      </Text>
    </BaseDividerWithText>
  );
};

export default DividerWithText;
