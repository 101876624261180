import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import {
  type DetailedHTMLProps,
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
} from 'react';

import ErrorAccordion from '@/components/ErrorAccordion';

import type { ErrorDetailsProps } from '../ArrayDataNestedForm';
import Label from './Label';

type Props = {
  label?: string;
  options: { label: string; value: string }[];
  values: string[];
  required?: boolean;
  checkboxClassName?: string;
  labelClassName?: string;
  checkboxCheckIconColor?: 'black' | 'white';
  checkboxValueClassName?: (checked: boolean) => string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
  ErrorDetailsProps<string>;

const CheckboxList = (
  {
    options,
    label,
    values,
    required = false,
    error,
    checkboxClassName,
    labelClassName,
    canHaveErrorMessage = true,
    checkboxCheckIconColor = 'black',
    checkboxValueClassName,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <div className="flex flex-col gap-1">
      {label && <Label required={required}>{label}</Label>}
      <div className="flex flex-col gap-2">
        {options.map((option) => (
          <div
            key={option.value}
            className={classNames(
              'flex rounded-lg border-2 bg-primary-20% transition-colors',
              {
                'border-error': error,
                'border-primary-700': !error,
              },
              checkboxClassName
            )}
          >
            <label
              htmlFor={option.value + props.name}
              className={classNames(
                'flex flex-1 cursor-pointer items-center p-4',
                labelClassName
              )}
            >
              <input
                className="hidden"
                type="checkbox"
                id={option.value + props.name}
                value={option.value}
                {...props}
                ref={ref}
              />
              <span
                className={classNames(
                  'mr-4 inline-flex h-5 w-5 items-center justify-center rounded-sm border-2',
                  checkboxValueClassName?.(values.includes(option.value))
                )}
              >
                {values && values.includes(option.value) && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={checkboxCheckIconColor}
                  />
                )}
              </span>
              {option.label}
            </label>
          </div>
        ))}
        {canHaveErrorMessage && <ErrorAccordion error={error} />}
      </div>
    </div>
  );
};

export default forwardRef(CheckboxList);
