import { faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@mindpal-co/mindpal-ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import router from 'next/router';
import { type FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import type { InferType } from 'yup';
import { array, boolean, mixed, number, object, string } from 'yup';

import RecruiteeLogo from '@/assets/recruiteeLogo.svg';
import Button from '@/components/Button';
import FileInput from '@/components/Form/Fields/FileInput';
import TextInputField from '@/components/Form/Fields/TextInputField';
import { DocumentsFileTypes } from '@/config/filesTypes';
import { useExternalCandidatesCollections } from '@/hooks/useExternalCandidatesCollections';
import { useProfile } from '@/hooks/useProfile';
import { useSettingsQuery } from '@/hooks/useSettingsQuery';
import { queries } from '@/queries';
import { AdjustToOfferFields } from '@/services/cvGenerator';
import type { ExternalCandidate } from '@/services/externalCandidates';
import { postCvConstruct } from '@/services/externalCandidates';
import {
  TranslateLanguages,
  translateLanguagesOptions,
} from '@/services/settings';

import CheckboxList from '../Form/Fields/CheckboxList';
import Label from '../Form/Fields/Label';
import MultiLineInput from '../Form/Fields/MultiLineInput';
import { Select } from '../Select';
import Text from '../Text';
import { Accordion, AccordionContent, AccordionItem } from '../ui/accordion';
import { Switch } from '../ui/switch';
import { TooltipContent } from '../ui/tooltip';
import ForceGenerateDialog from './ForceGenerateDialog';

export const AdjustToOfferFieldsOptions = Object.entries(
  AdjustToOfferFields
).map((field) => ({
  value: field[1],
  label: field[0],
}));

type Props = {
  onRequestClose?: () => void;
  defaultValue?: File[];
  onSubmitPressed?: () => void;
  onSuccess?: (data: ExternalCandidate) => void;
  defaultCollections?: number[];
};
const AddCVForm: FC<Props> = ({
  onRequestClose,
  defaultValue,
  onSubmitPressed,
  onSuccess,
  defaultCollections,
}) => {
  const [dialogCloseScheduled, setDialogCloseScheduled] = useState(false);
  const [isAdjustToOfferEnable, setIsAdjustToOfferEnable] = useState(false);
  const [forceGenerateDialogOpen, setForceGenerateDialogOpen] = useState<
    {
      cvData: { id?: string; cv?: File };
      language: string;
      anonymization: boolean;
      generateResume: boolean;
    }[]
  >([]);
  const { data: profileData } = useProfile();
  const { data: settingsData } = useSettingsQuery();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm<InferType<typeof schema>>({
    resolver: yupResolver(schema),
    defaultValues: {
      cv: [],
      id: '',
      collections: null,
      offerTitle: '',
      offerDescription: '',
      fieldsToAdjustToOffer: AdjustToOfferFieldsOptions.map(
        (option) => option.value
      ),
      language: settingsData?.preferredCvLanguage ?? TranslateLanguages.ENGLISH,
      anonymization: true,
      generateResume: true,
    },
    context: { isAdjustToOfferEnable },
  });

  const { data: collectionsData } = useExternalCandidatesCollections({
    staleTime: 1000 * 60 * 5,
  });

  const collectionsOptions = collectionsData?.results.map((collection) => ({
    value: collection.id,
    label: collection.title,
  }));

  const { mutate, isLoading } = useMutation({
    mutationKey: ['addCV'],
    mutationFn: postCvConstruct,
    meta: { silent: true },
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(queries.cvGenerator.list._def);
      queryClient.invalidateQueries(queries.cvGenerator.userCvUsage.queryKey);
      queryClient.invalidateQueries(queries.externalCandidates.list._def);
      if ((variables.collections?.length ?? 0) > 0)
        queryClient.invalidateQueries(
          queries.externalCandidates.collections.queryKey
        );
      onSuccess?.(response);
      setDialogCloseScheduled(true);
    },
    onError: (err, variables) => {
      if (!(err instanceof AxiosError)) return;
      if (err.response?.status === 420)
        return setForceGenerateDialogOpen((prev) => [...prev, variables]);
      if (err.response?.status === 403) {
        toast.error(err?.response?.data?.detail ?? 'Something went wrong');
        return router.push('/pricing');
      }
      toast.error(err?.response?.data?.detail ?? 'Something went wrong');
    },
  });

  const onSubmit = async (data: InferType<typeof schema>) => {
    if (data.cv && (data.cv.length ?? 0) > 0)
      data.cv.forEach((cv) => {
        mutate({
          cvData: {
            cv,
            id: undefined,
          },
          collections: data.collections?.map(({ value }) => value),
          language: data.language,
          anonymization: data.anonymization,
          generateResume: data.generateResume,
          offerDescription: isAdjustToOfferEnable ? data.offerDescription : '',
          offerTitle: isAdjustToOfferEnable ? data.offerTitle : '',
          fieldsToAdjustToOffer: isAdjustToOfferEnable
            ? data.fieldsToAdjustToOffer
            : [],
        });
      });
    if (data.id && (data.id.length ?? 0) > 0)
      data.id.split(',').forEach((id) => {
        mutate({
          cvData: {
            cv: undefined,
            id: id.trim(),
          },
          language: data.language,
          anonymization: data.anonymization,
          generateResume: data.generateResume,
          offerDescription: isAdjustToOfferEnable ? data.offerDescription : '',
          offerTitle: isAdjustToOfferEnable ? data.offerTitle : '',
          fieldsToAdjustToOffer: isAdjustToOfferEnable
            ? data.fieldsToAdjustToOffer
            : [],
        });
      });
  };

  useEffect(() => {
    setValue('cv', defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setValue(
      'collections',
      defaultCollections?.map((collectionId) => ({
        value: collectionId,
        label:
          collectionsOptions?.find(
            ({ value: optionValue }) => optionValue === collectionId
          )?.label ?? '',
      })) ?? null
    );
  }, [collectionsData, defaultCollections]);

  useEffect(() => {
    if (
      !dialogCloseScheduled ||
      isLoading ||
      forceGenerateDialogOpen.length > 0
    )
      return;
    setDialogCloseScheduled(false);
    onRequestClose?.();
  }, [dialogCloseScheduled, forceGenerateDialogOpen.length, isLoading]);
  return (
    <div className="flex w-full max-w-3xl flex-1 items-center justify-center self-center">
      <form
        className="flex w-full flex-col gap-8"
        onSubmit={(e) => {
          handleSubmit((formData) => onSubmit(formData))(e);
          if (!isLoading) onSubmitPressed?.();
        }}
      >
        <Controller
          control={control}
          name="cv"
          render={({ field: { onChange, value, name } }) => (
            <FileInput
              name={name}
              labelClassName="text-neutral-1000"
              variant="transparent"
              label="Resume Files"
              accept={DocumentsFileTypes}
              onChange={onChange}
              value={value}
              error={errors.cv?.message}
              multiple
            />
          )}
        />

        {profileData?.accountType === 'ADMIN' && (
          <div className=" hidden flex-1 flex-col gap-1">
            <Label className="flex items-center gap-1 text-neutral-1000">
              <RecruiteeLogo />
              ID (Optional)
            </Label>
            <TextInputField
              labelClassName="text-neutral-1000"
              variant="transparent"
              {...register('id')}
              placeholder="123456"
              error={errors.id?.message}
            />
          </div>
        )}
        <Controller
          control={control}
          name="collections"
          render={({ field: { ref: _ref, ...field } }) => (
            <Select
              label="Collections"
              labelClassName="text-neutral-1000"
              variant="transparent"
              options={collectionsOptions}
              isMulti
              {...field}
            />
          )}
        />
        <div className="flex items-center gap-2 rounded-lg py-4">
          <FontAwesomeIcon icon={faGears} className="text-neutral-1000" />
          <Label className="flex flex-1 items-center justify-between text-center text-neutral-1000">
            <div className="flex items-center gap-2">Generate resume</div>

            <Controller
              control={control}
              name="generateResume"
              render={({ field: { onChange, value, name, onBlur, ref } }) => (
                <Switch
                  data-cy="generate-cv-switch"
                  checked={value}
                  onCheckedChange={onChange}
                  name={name}
                  onBlur={onBlur}
                  ref={ref}
                />
              )}
            />
          </Label>
        </div>
        <Accordion
          value={watch('generateResume') ? 'generateCVOptions' : ''}
          type="single"
        >
          <AccordionItem className="!border-b-0" value="generateCVOptions">
            <AccordionContent>
              <div className="flex flex-col gap-8">
                <Controller
                  control={control}
                  name="language"
                  render={({ field: { name, onChange, value } }) => (
                    <Select
                      menuPortalTarget={document.querySelector('body')}
                      classNames={{
                        singleValue: () => '!font-normal',
                        menu: () => '!z-[9999] !pointer-events-auto',
                        menuPortal: () => '!z-[9999] !pointer-events-auto',
                      }}
                      className="flex-1"
                      labelClassName="text-neutral-1000"
                      label="Output resume language"
                      value={translateLanguagesOptions.find(
                        ({ value: optionValue }) => optionValue === value
                      )}
                      onChange={(selectedOption) =>
                        onChange(selectedOption?.value)
                      }
                      variant="transparent"
                      options={translateLanguagesOptions}
                      name={name}
                    />
                  )}
                />
                <div className="felx-row flex justify-between">
                  <Label className="flex items-center gap-2 text-neutral-1000">
                    <Icon icon="bi:stars" className="text-primary-600" /> Adjust
                    to offer{' '}
                    <TooltipProvider>
                      <Tooltip delayDuration={0}>
                        <TooltipContent className="max-w-[13rem] rounded-lg bg-neutral-200">
                          <Text className="text-neutral-1000">
                            Paste the Job offer and adjust the resume by sorting
                            the skills and improving candidates’ summary.
                          </Text>
                        </TooltipContent>
                        <TooltipTrigger>
                          <Icon
                            icon="ri:question-line"
                            className="text-xl text-neutral-300"
                          />
                        </TooltipTrigger>
                      </Tooltip>
                    </TooltipProvider>
                  </Label>
                  <Switch
                    checked={isAdjustToOfferEnable}
                    onCheckedChange={() =>
                      setIsAdjustToOfferEnable((prev) => !prev)
                    }
                  />
                </div>
                <Accordion
                  type="single"
                  value={isAdjustToOfferEnable ? 'enable' : undefined}
                >
                  <AccordionItem value="enable">
                    <AccordionContent className="text-neutral-1000">
                      <div className="flex flex-col gap-4">
                        <TextInputField
                          variant="transparent"
                          label="Job Title"
                          {...register('offerTitle')}
                          error={errors.offerTitle?.message}
                        />
                        <MultiLineInput
                          rows={4}
                          variant="transparent"
                          label="Job Description"
                          {...register('offerDescription')}
                          error={errors.offerDescription?.message}
                        />
                        <CheckboxList
                          labelClassName="!p-0 color-primary-500"
                          checkboxClassName="bg-transparent border-transparent b-0"
                          checkboxValueClassName={(checked) =>
                            classNames({
                              '!bg-primary-800 !text-neutral-100 border-primary-800':
                                checked,
                            })
                          }
                          checkboxCheckIconColor="white"
                          label="Fields to adjust"
                          values={watch('fieldsToAdjustToOffer') ?? []}
                          options={AdjustToOfferFieldsOptions}
                          {...register('fieldsToAdjustToOffer')}
                          error={errors.fieldsToAdjustToOffer?.message}
                        />
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
                <div className="flex items-center gap-2 rounded-lg py-4">
                  <Icon
                    icon="mdi:incognito"
                    className="text-xl text-neutral-1000"
                  />
                  <Label className="flex flex-1 items-center justify-between text-center text-neutral-1000">
                    <div className="flex items-center gap-2">
                      Anonymize this resume
                      <TooltipProvider>
                        <Tooltip delayDuration={0}>
                          <TooltipContent className="max-w-[13rem] rounded-lg bg-neutral-200">
                            <Text className="text-neutral-1000">
                              Remove sensitive data from this resume (e.g.,
                              surname and contact information).
                            </Text>
                          </TooltipContent>
                          <TooltipTrigger>
                            <Icon
                              icon="ri:question-line"
                              className="text-xl text-neutral-300"
                            />
                          </TooltipTrigger>
                        </Tooltip>
                      </TooltipProvider>
                    </div>

                    <Controller
                      control={control}
                      name="anonymization"
                      render={({
                        field: { onChange, value, name, onBlur, ref },
                      }) => (
                        <Switch
                          checked={value}
                          onCheckedChange={onChange}
                          name={name}
                          onBlur={onBlur}
                          ref={ref}
                        />
                      )}
                    />
                  </Label>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="flex flex-col ">
          <Button
            data-cy="add-cv-submit"
            variant="primary"
            isLoading={isLoading}
            type="submit"
          >
            Submit
          </Button>
        </div>
      </form>
      {forceGenerateDialogOpen.slice(0, 1).map((requestData, i) => {
        const removeRequestFromList = () =>
          setForceGenerateDialogOpen((prev) =>
            prev.filter((_data, index) => i !== index)
          );

        return (
          <ForceGenerateDialog
            key={JSON.stringify(requestData)}
            onOpenChange={(open) => {
              if (!open) removeRequestFromList();
            }}
            open
            onForceGenerate={() => {
              mutate({
                ...requestData,
                forceGenerate: true,
              });
              removeRequestFromList();
            }}
            onCancel={() => {
              mutate({
                ...requestData,
                forceGenerate: false,
              });
              removeRequestFromList();
            }}
            isLoading={isLoading}
            cvData={requestData.cvData}
          />
        );
      })}
    </div>
  );
};

const schema = object().shape(
  {
    cv: array()
      .of(mixed<File>().defined())
      .when('id', {
        is: (id: string) => id.length === 0,
        then: (innerSchema) => innerSchema.required().min(1),
        otherwise: (innerSchema) => innerSchema,
      }),

    id: string().when('cv', {
      is: (cv: any | null) => cv === null,
      then: (innerSchema) => innerSchema.required(),
      otherwise: (innerSchema) => innerSchema,
    }),
    language: string()
      .required()
      .oneOf(Object.values(TranslateLanguages))
      .required(),
    anonymization: boolean().defined(),
    collections: array()
      .of(object({ value: number().defined(), label: string().defined() }))
      .nullable()
      .defined(),
    generateResume: boolean().defined(),
    offerTitle: string().when('$isAdjustToOfferEnable', {
      is: true,
      then: (value) => value.required('Job title is required'),
    }),
    offerDescription: string().when('$isAdjustToOfferEnable', {
      is: true,
      then: (value) => value.required('Job description is required'),
    }),
    fieldsToAdjustToOffer: array()
      .of(string().oneOf(Object.values(AdjustToOfferFields)).required())
      .required()
      .when('$isAdjustToOfferEnable', {
        is: true,
        then: (value) => value.min(1, 'At least one field is required'),
      }),
  },
  [['id', 'cv']]
);

export default AddCVForm;
